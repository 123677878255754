<template>
  <!-- <v-btn class="ml-2" min-width="0" text to="/" exact> -->
  <v-btn class="ml-0" min-width="0" text>
    <v-icon>mdi-web</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "DefaultLocale",
};
</script>
