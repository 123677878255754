<template>
  <v-btn
    class="ml-0"
    min-width="0"
    text
    to="/app/analytics/oil/anomalies/max"
    exact
  >


      <v-icon>mdi-database-alert-outline</v-icon>
  </v-btn>
</template>

<script>

export default {
  name: "DefaultGoIncident",
};
</script>
